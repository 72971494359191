import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube, faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import config from '../config'
import '../scss/footer.scss';

export default function Footer() {

    const { t } = useTranslation();

    const [activeMenu, setActiveMenu] = useState(0);

    return (
        <footer>
            <div className="footer-container" >
                <div className="container" >
                    <div className="row" >
                        <div className="col-12 col-lg-6" >
                            <div className="image-brand" >
                                <img className="img-fluid" src="https://app-mail-asset.bizzw.com/img/util/logo-reverse.png" alt="Logo design letter" />
                                <span className="image-brand-name white-color ml-2" >{t('footer.title')}</span>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6" >
                            <div className="large-screen-align-right mt-3 mt-lg-0" >
                                <div className="main-button-container" >
                                    <a className="main-button" href={`${config.mainWebsite}/process/letter/choose`} >{t('footer.button')}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12" >
                            <div className="footer-dot-separator" ></div>
                        </div>
                        <div className="col-12 col-lg-8" >
                            <div className="container-fluid" >
                                <div className="row" >
                                    <div className={`card col-12 col-lg-3 ${activeMenu === 1 ? 'active' : ''}`} >
                                        <div className="card-header" onClick={() => setActiveMenu(1)} >
                                            <h2 className="footer-card-title" >
                                                <span className="font-weight-bold" >{t('footer.link.block.1.title')}</span>
                                            </h2>
                                        </div>
                                        <div className="card-body" >
                                            <a className="footer-link" href={`${config.mainWebsite}/process/letter/choose`} >{t('footer.link.block.1.link.1')}</a>
                                            <a className="footer-link" href={`${config.mainWebsite}/process/card/format`} >{t('footer.link.block.1.link.2')}</a>
                                            <a className="footer-link" href={`${config.mainWebsite}/#sendingOptions`} >{t('footer.link.block.1.link.3')}</a>
                                            <a className="footer-link" href={`${config.mainWebsite}/`} >{t('footer.link.block.1.link.4')}</a>
                                            <a className="footer-link" href={`${config.mainWebsite}/faq`} >{t('footer.link.block.1.link.5')}</a>
                                            <a className="footer-link" href={`${config.mainWebsite}/process/letter/choose`} >{t('footer.link.block.1.link.6')}</a>
                                            <a className="footer-link" href={`${config.mainWebsite}/offer`} >{t('footer.link.block.1.link.7')}</a>
                                        </div>
                                    </div>
                                    <div className={`card col-12 col-lg-4 ${activeMenu === 2 ? 'active' : ''}`} >
                                        <div className="card-header" onClick={() => setActiveMenu(2)} >
                                            <h2 className="footer-card-title" >
                                                <span className="font-weight-bold" >{t('footer.link.block.2.title')}</span>
                                            </h2>
                                        </div>
                                        <div className="card-body" >
                                            <a className="footer-link" href={`${config.mainWebsite}/contact`} >{t('footer.link.block.2.link.1')}</a>
                                            <a className="footer-link" href={`${config.mainWebsite}/#`} >{t('footer.link.block.2.link.2')}</a>
                                            <a className="footer-link" href={`${config.mainWebsite}/guc`} >{t('footer.link.block.2.link.3')}</a>
                                            <a className="footer-link" href={`${config.mainWebsite}/cookie`} >{t('footer.link.block.2.link.4')}</a>
                                            <a className="footer-link" href={`${config.mainWebsite}/legal`} >{t('footer.link.block.2.link.5')}</a>
                                            <a className="footer-link" href={`${config.mainWebsite}/privacy`} >{t('footer.link.block.2.link.6')}</a>
                                            <a className="footer-link" href={`${config.mainWebsite}/#`} >{t('footer.link.block.2.link.7')}</a>
                                            <a className="footer-link" href={`${config.mainWebsite}/contact`} >{t('footer.link.block.2.link.8')}</a>
                                            <a className="footer-link" href={`${config.mainWebsite}/waranty`} >{t('footer.link.block.2.link.9')}</a>
                                            <a className="footer-link" href={`${config.mainWebsite}/unsubscribe`} >{t('footer.link.block.2.link.10')}</a>
                                        </div>
                                    </div>
                                    <div className={`card col-12 col-lg-4 ${activeMenu === 3 ? 'active' : ''}`} >
                                        <div className="card-header" onClick={() => setActiveMenu(3)} >
                                            <h2 className="footer-card-title" >
                                                <span className="font-weight-bold" >{t('footer.link.block.3.title')}</span>
                                            </h2>
                                        </div>
                                        <div className="card-body" >
                                            <a className="footer-link" href={`${config.mainWebsite}/login`} >{t('footer.link.block.3.link.1')}</a>
                                            <a className="footer-link" href={`${config.mainWebsite}/`} >{t('footer.link.block.3.link.2')}</a>
                                            <a className="footer-link" href={`${config.mainWebsite}/unsubscribe`} >{t('footer.link.block.3.link.3')}</a>
                                            <a className="footer-link" href="https://faq.lettre24.com" >{t('footer.link.block.3.link.4')}</a>
                                            <a className="footer-link" href={`${config.mainWebsite}/avis-lettre24`} >{t('footer.link.block.3.link.5')}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 d-lg-none" >
                            <div className="footer-dot-separator" ></div>
                        </div>
                        <div className="col-12 col-lg-3 offset-lg-1" >
                            <div className="large-screen-align-right" >
                                <div className="large-screen-align-right-content text-left" >
                                    <p className="small-center font-weight-bold my-3 py-0" >{t('footer.social.title')}</p>
                                    <div className="row" >
                                        <div className="col-3 social-icon-container" >
                                            <FontAwesomeIcon icon={faYoutube} />
                                        </div>
                                        <div className="col-3 social-icon-container" >
                                            <FontAwesomeIcon icon={faFacebook} />
                                        </div>
                                        <div className="col-3 social-icon-container" >
                                            <FontAwesomeIcon icon={faTwitter} />
                                        </div>
                                        <div className="col-3 social-icon-container" >
                                            <FontAwesomeIcon icon={faInstagram} />
                                        </div>
                                    </div>
                                    <p className="small-center font-weight-bold my-3" >{t('footer.contact.title')}</p>
                                    <div className="footer-phone-mail" >
                                        <div className="phone-block" >
                                            <FontAwesomeIcon icon={faPhoneAlt} />
                                        </div>
                                        <div className="phone-block w-100 text-right" >
                                            <a href={`tel:${t('footer.contact.phone.number')}`} >
                                                <div className="footer-phone" >
                                                    <span>{t('footer.contact.phone.number')}</span>
                                                </div>
                                            </a>
                                            <span className="small-info" >{t('footer.contact.phone.daytime.0')}</span>
                                        </div>
                                        <div className="mail-block" >
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </div>
                                        <div className="mail-block w-100 text-right" >
                                            <a href={`mailto:${t('footer.contact.email.address')}`} >
                                                <span className="text-align-right" >{t('footer.contact.email.address')}</span>
                                            </a>
                                            <span className="small-info" >{t('footer.contact.phone.daytime.1')}</span>
                                            <span className="small-info" >{t('footer.contact.phone.daytime.2')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12" >
                            <div className="footer-dot-separator" ></div>
                        </div>
                        <div className="col-12 col-lg-8 offset-lg-2" >
                            <p className="footer-bottom" >{t('footer.bottom.description')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}