import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as farStart } from '@fortawesome/free-regular-svg-icons'
import { faStar as fasStart } from '@fortawesome/free-solid-svg-icons'
import '../scss/home.scss'

export default function Home() {

    const { t } = useTranslation();

    const adviceCount = parseInt(t('customerReturn.count', '6'));

    const elements = [];
    for (let i = adviceCount; i > 0; i--) {

        const stars = [];
        for (let j = 1; j <= 5; j++) {
            const haveStar = parseInt(t(`customerReturn.element.${i}.star`, '4')) >= j
            stars.push(<FontAwesomeIcon icon={haveStar ? fasStart : farStart} key={j} />)
        }

        elements.push(
        <div className="col-12 col-lg-4" key={i} >
            <div className="home-box" >
                <div className="home-box-star" >{stars}</div>
                <div className="home-box-date" >
                    <span>{t(`customerReturn.element.${i}.date`)}</span>
                </div>
                <div className="home-box-message my-3" >{t(`customerReturn.element.${i}.message`)}</div>
                <div className="home-box-from" >
                    <span>{t(`customerReturn.element.${i}.from`)}</span>
                </div>
            </div>
        </div>
        )

    }

    return (
        <>
            <Helmet>
                <title>{t('home.meta.title')}</title>
                <meta name="description" content={t('home.meta.description')} />
            </Helmet>
            <div className="home" >
                <div className="container my-5" >
                    <h1 className="home-title" >{t('home.title')}</h1>
                    <div className="mb-3" dangerouslySetInnerHTML={{__html: t('home.description')}} />
                    <div className="mb-5" dangerouslySetInnerHTML={{__html: t('home.info')}}/>
                    <div className="row" >{elements}</div>
                </div>
            </div>
        </>
    )

}