import { useTranslation } from 'react-i18next';
import { Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import InfoBanner from './InfoBanner';
import config from '../config'
import '../scss/header/header.scss'
import logo from '../img/logo.png'
import phoneIcon from '../img/header/phone.svg'
import contactIcon from '../img/header/contact.svg'

export default function Header() {

    const { t } = useTranslation();

    return (
        <header>
            <InfoBanner />
            <div className="header" >
                <div className="container" >
                    <Navbar bg="light" expand="lg" >
                        <a className="navbar-brand" href={config.mainWebsite} >
                            <div className="image-brand" >
                                <img className="img-fluid" src={logo} alt="Lettre24" />
                                <span className="image-brand-name" >{t('header.title')}</span>
                            </div>
                        </a>
                        <span className="header-separator" />
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="header-navbar-nav">
                            <Nav className="mr-auto">
                                <Nav.Link href={`${config.mainWebsite}`}>{t('header.menu.home')}</Nav.Link>
                                <Nav.Link href={`${config.mainWebsite}/process/letter/choose`}>{t('header.menu.letter')}</Nav.Link>
                                <Nav.Link href={`${config.mainWebsite}/unsubscribe`}>{t('header.menu.unsubscribe')}</Nav.Link>
                                <Nav.Link href={`${config.mainWebsite}/faq`}>{t('header.menu.faq')}</Nav.Link>
                                <Nav.Link href={`${config.mainWebsite}/offer`}>{t('header.menu.offer')}</Nav.Link>
                                <span className="header-separator" />
                                <div className="header-svg-nav-item" >
                                    <a href={`${config.mainWebsite}/unsubscribe`} >
                                        <img className="img-fluid header-svg" src={contactIcon} alt="Contact" />
                                    </a>
                                    <a href={`tel:${t('global.phone')}`} >
                                        <img className="img-fluid header-svg" src={phoneIcon} alt="Phone" />
                                    </a>
                                </div>
                                <div className="header-login" >
                                    <FontAwesomeIcon icon={faSignInAlt} />
                                    <a href={`${config.mainWebsite}/login`} >{t('header.login')}</a>
                                </div>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>
        </header>
    )

}