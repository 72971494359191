import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../scss/header/header.scss'

export default function InfoBanner() {

    const { t } = useTranslation();

    const [active, setActive] = useState(false);

    const [hide, setHide] = useState(!!localStorage.getItem('infoBanner'));

    function hideBanner() {
        localStorage.setItem('infoBanner', '1');
        setHide(true);
    }

    if (hide) return null;

    return (
        <div className={`header-info-banner ${active ? 'active' : ''}`} >
            <div className="container" >
                <div className="header-info-banner-head" >
                    <div className="header-info-banner-head-close" onClick={() => hideBanner()} >&times;</div>
                    <div
                        className="header-info-banner-head-text"
                        onClick={() => setActive(!active)}
                        dangerouslySetInnerHTML={{ __html: t('header.infoBanner.head') }}
                    />
                </div>
                <div className="header-info-banner-content" >
                    <div
                        className="header-info-banner-content-text"
                        dangerouslySetInnerHTML={{ __html: t('header.infoBanner.text') }}
                    />
                </div>
            </div>
        </div>
    )

}