const config = {
    competitiveApi: {
        websiteName: 'app-mail-advice'
    },
    mainWebsite: 'https://lettre24.com',
    tradEnv: 'staging'
}

if(['lettre24-avis.com'].indexOf(window.location.hostname) !== -1) {
    config.competitiveApi.websiteName = 'lettre24-avis.com';
    config.gtm = 'GTM-WMK7P8N';
    config.tradEnv = 'live';
}

export default config;